
import React from "react"

import NnreturnPage from "../../components/negosyonow/controls/nnreturneditor";

const HubreturnPage = ({location}) => {
	return <NnreturnPage
				location={location}
				allowadd={true}
				methodid={0}
				encodertype={3}
			/>
}

export default HubreturnPage;
